import {
    Alert,
    getPayloadObject,
    getTelemetryAttributes,
    INodeProps,
    ITelemetryContent,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    TelemetryConstant
} from '@msdyn365-commerce-modules/utilities';
import { IAny, ICoreContext, IGeneric} from '@msdyn365-commerce/core';
import React from 'react';
import { ReviewConcernCategory } from '../../../actions/inputs/report-review-concern-input';
import { IRnrKeyValuePair } from '../../../common/rnrInterface';

export interface IReportReviewModalProps {
    resources: IReportReviewModalResources;
    context:  ICoreContext<IGeneric<IAny>>;
    isOpen: boolean;
    returnRef: React.RefObject<HTMLButtonElement> | undefined;
    reportedKey: string;
    hasError: boolean;
    telemetryContent?: ITelemetryContent;
    onModalToggle():void;
    onRadioChange(event: React.ChangeEvent<HTMLInputElement>): void;
    reportReview(): void;
}

export interface IReportReviewModalResources {
    reportConcernText: string;
    feedbackThankYouText: string;
    reportModalMessage: string;
    reportSubmittedMessage: string;
    reportSpamText: string;
    offensiveContentText: string;
    profanityContentText: string;
    okReportReviewText: string;
    cancelReportReviewText: string;
    reportReviewButtonText: string;
    errorMessageText: string;
}

export interface IReportReviewModalViewProps {
    modal: INodeProps;
    modalHeader: INodeProps;
    modalFooter: INodeProps;
    modalBody: INodeProps;
    header: React.ReactElement;
    headerSubmitted: React.ReactElement;
    cancelButton: React.ReactElement;
    submitButton: React.ReactElement;
    succesfulButton: React.ReactElement;
    reportMessage: React.ReactElement;
    reportSubmittedMessage: React.ReactElement;
    radioButtons: React.ReactElement;
    error: React.ReactElement;
}

let reportConcernDropdownOptions: IRnrKeyValuePair[];

/**
 * CartLineItems component
 */

export const ReportReviewModal = (props: IReportReviewModalProps):IReportReviewModalViewProps  => {

    const payLoad = getPayloadObject('click', props.telemetryContent!, TelemetryConstant.SubmitReview);
    const submitReviewAttribute = getTelemetryAttributes(props.telemetryContent!, payLoad);
    payLoad.contentAction.etext = TelemetryConstant.SuccessButton;
    const successAttribute = getTelemetryAttributes(props.telemetryContent!, payLoad);
    payLoad.contentAction.etext = TelemetryConstant.Cancel;
    const cancelAttribute = getTelemetryAttributes(props.telemetryContent!, payLoad);

    reportConcernDropdownOptions = [
        { key: ReviewConcernCategory.SpamAdvertising, value: props.resources.reportSpamText },
        { key: ReviewConcernCategory.OffensiveContent, value: props.resources.offensiveContentText },
        { key: ReviewConcernCategory.Profanity, value: props.resources.profanityContentText }
    ];

    return {
        modal: {tag: Modal, className: 'ms-report-review-modal msc-modal-input-required', toggle: props.onModalToggle, applicationNode: 'renderPage', isOpen: props.isOpen, returnFocusRef: props.returnRef},
        modalHeader: {tag: ModalHeader, toggle: props.onModalToggle, className: 'ms-report-review-modal-header'},
        modalFooter: {tag: ModalFooter, className: 'ms-report-review-modal-footer'},
        modalBody: {tag: ModalBody, className: 'ms-report-review-modal-body'},
        header: <p className='ms-reviews-list__report-header'>{props.resources.reportConcernText}</p>,
        headerSubmitted: <p className='ms-reviews-list__report-header'>{props.resources.feedbackThankYouText}</p>,
        cancelButton: <button className='msc-btn ms-reviews-list__cancel' onClick={props.onModalToggle} {...cancelAttribute}>{props.resources.cancelReportReviewText}</button>,
        submitButton: <button className='msc-btn ms-reviews-list__submit' onClick={props.reportReview} {...submitReviewAttribute}> {props.resources.reportReviewButtonText || 'Report'}</button>,
        succesfulButton: <button className='msc-btn ms-reviews-list__success' onClick={props.onModalToggle} {...successAttribute}>{props.resources.okReportReviewText}</button>,
        reportMessage: <p className='ms-reviews-list__report-message'>{props.resources.reportModalMessage}</p>,
        reportSubmittedMessage: <p className='ms-reviews-list__report-message'>{props.resources.reportSubmittedMessage}</p>,
        radioButtons: radioPairs(props),
        error: <Alert className='ms-reviews-list__report-error' isOpen={props.hasError} color='danger'>{props.resources.errorMessageText}</Alert>
    };
};

const radioPairs = (props: IReportReviewModalProps) => {
    const pairs = [];
    for (const option of reportConcernDropdownOptions) {
        const inputId = `report-review-${option.key}`;
        const checked = option.key === props.reportedKey;
        pairs.push(
            <div className='ms-report-review-modal-pair' key={option.key}>
                <input
                    className='msc-radio-input'
                    type='radio'
                    name='report-error'
                    id={inputId}
                    data-key={option.key}
                    value={option.value}
                    checked={checked}
                    aria-checked={checked}
                    onChange={props.onRadioChange}
                />
                <label className='msc-radio-label' htmlFor={inputId}>{option.value}</label>
            </div>
        );
    }

    return (
        <fieldset>
            {pairs}
        </fieldset>
    );
};