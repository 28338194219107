import { getFallbackImageUrl, getProductUrlSync, OrderTemplate, OrderTemplateLine } from '@msdyn365-commerce-modules/retail-actions';
import { Alert, Button, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { AddOrderTemplateToCartComponent, ILinesAddedToCartDialogResources } from '@msdyn365-commerce/components';
import { getUrlSync, ICoreContext, IImageSettings, Image, ITelemetry } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as React from 'react';
import { IOrderTemplateActionMessageState } from '../order-template-list';

export interface ISingleOrderTemplateProps extends ProductList {
    orderTemplateState: IOrderTemplateActionMessageState;
    name?: string;
    orderTemplate: OrderTemplate;
    context: ICoreContext;
    imageSettings?: IImageSettings;
    enableImageProductLink?: boolean;
    removeFromOrderTemplateListText: string;
    addToBagText: string;
    index: number;
    itemKey: string;
    telemetry: ITelemetry;
    moduleId: string;
    moduleTypeName: string;
    handlers: {
        onRemoveOrderTemplate(id: string): void | undefined;
        onDismiss(): void;
    };
    resources: {
        closeWindowButtonText: string;
        addToCartFailureMessage: string;
        addToCartSuccessMessage: string;
        addToCartProcessMessage: string;
        linesAddedToCartDialogResources: ILinesAddedToCartDialogResources;
    };
    telemetryContent?: ITelemetryContent;
}

export interface ISingleOrderTemplateViewProps {
    key: string;
    orderTemplateImage: React.ReactNode;
    addToCartButton?: React.ReactNode;
    removeButton?: React.ReactNode;
    orderTemplateStatusMessage?: React.ReactNode;
    orderTemplateName?: React.ReactNode;
    orderTemplateAriaLabel?: string;
    orderTemplateHeaderDivider?: React.ReactNode;
}

const _removeItemFromOrderTemplateList = (props: ISingleOrderTemplateProps): void => {
    const { onRemoveOrderTemplate } = props.handlers;
    const { itemKey } = props;

    onRemoveOrderTemplate && itemKey && onRemoveOrderTemplate(itemKey);
};

const OrderTemplateActions = {
    removeOrderTemplate: _removeItemFromOrderTemplateList,
};

export const OrderTemplateRender = (input: ISingleOrderTemplateProps): ISingleOrderTemplateViewProps | null => {
    const { Id, orderTemplate, removeFromOrderTemplateListText, orderTemplateState, handlers, name, imageSettings, context, telemetryContent, enableImageProductLink } = input;

    if (!Id) {
        return null;
    }

    const _getOrderTemplatePageUrl = (): string => {
        const url = getUrlSync('ordertemplate', context.actionContext) || '';
        const separator = url.includes('?') ? '&' : '?';
        if (Id) {
            return `${url}${separator}id=${Id}`;
        } else {
            return '';
        }
    };

    const removeOrderTemplateAction = (event: React.MouseEvent<HTMLElement>) => { return OrderTemplateActions.removeOrderTemplate(input); };
    const { onDismiss } = handlers;

    return {
        key: `${Id}-template`,
        orderTemplateImage: _renderOrderTemplateImages(context, orderTemplate.orderTemplateLines, imageSettings, enableImageProductLink),
        orderTemplateAriaLabel: _renderAriaLabel(input),
        orderTemplateName:
        (
            <div>
                <div className='ms-order-template__list-item-title'><a href={_getOrderTemplatePageUrl()}>{name}</a></div>
                <div className='ms-order-template__line-count'>{orderTemplate.totalLines} line items</div>
                <hr color='#F9F9F9'/>
            </div>
        ),
        addToCartButton:
        (
            <div className = 'ms-order-template__add-to-bag-button-div'>
                <AddOrderTemplateToCartComponent
                    id={Id}
                    context={context}
                    typeName={input.moduleTypeName}
                    data={{
                        orderTemplate: input.orderTemplate
                    }}
                    className='ms-order-template__add-to-bag-button'
                    addToCartText={input.addToBagText}
                    dialogStrings={input.resources}
                    telemetryContent={telemetryContent}
                />
            </div>
        ),
        removeButton:
        (
            <Button
                className='ms-order-template__remove-list'
                aria-label={ removeFromOrderTemplateListText }
                onClick={ removeOrderTemplateAction }
            />
        ),
        orderTemplateStatusMessage: orderTemplateState && orderTemplateState.isOpen && orderTemplateState.userMessage &&
        (
            <div className='ms-orderTemplate__status'>
                <Alert color={orderTemplateState.statusCssString} isOpen={orderTemplateState.isOpen} toggle={onDismiss}>
                    <span>{orderTemplateState.userMessage}</span>
                </Alert>
            </div>
        )

    };

};

const _renderOrderTemplateImages = (context: ICoreContext, orderTemplateLines?: OrderTemplateLine[],
                                    imageSettings?: IImageSettings, enableImageProductLink?: boolean): JSX.Element | null => {
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=140&h=140&m=6`, w: 0, h: 0 },
            lg: { q: `w=140&h=140&m=6`, w: 0, h: 0 },
            xl: { q: `w=140&h=140&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    if (orderTemplateLines) {
        const count = orderTemplateLines.length < 4 ? orderTemplateLines.length : 4;

        return (
            <div className='ms-order-template__image-container'>
                {orderTemplateLines?.slice(0, count).map(line => {
                    const productUrl = line.fullProduct ? getProductUrlSync(line.fullProduct.ProductDetails, context.actionContext, undefined) : '';

                    return (<Image
                        key={line.fullProduct?.ProductDetails.Name}
                        src={line.fullProduct?.ProductDetails.PrimaryImageUrl || ''}
                        fallBackSrc = {getFallbackImageUrl(line.fullProduct?.ProductDetails.ItemId, context.actionContext.requestContext.apiSettings)
                            || ''}
                        gridSettings={context.request.gridSettings!}
                        imageSettings={imageSettings || defaultImageSettings}
                        loadFailureBehavior='empty'
                        onClick={enableImageProductLink && ((event: MouseEvent) => window.location.assign(productUrl))}
                        className={enableImageProductLink ? 'product-link' : ''}
                    />);
                })}
            </div>
        );
    } else {
        return (
            <div className='ms-order-template__image-container'/>
        );
    }
};

const _renderAriaLabel = (props: ISingleOrderTemplateProps): string => {
    const { name } = props;
    return (`${name}`);
};