import { getPayloadObject, getTelemetryAttributes, ITelemetryContent, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import React from 'react';

export const SignInMessage: React.FC<{text: string}> = (props: {text: string}) => {
    return <p className='ms-write-review__sign-in'>{props.text}</p>;
};

export const SignInButton: React.FC<{text: string; href: string; ariaLabel: string; telemetryContent?: ITelemetryContent}> = (props: {text: string; href: string; ariaLabel: string; telemetryContent?: ITelemetryContent}) => {
    const {text, href, ariaLabel, telemetryContent} = props;
    const payLoad = getPayloadObject('click', telemetryContent!, TelemetryConstant.SignIn);
    const attributes = getTelemetryAttributes(telemetryContent!, payLoad);
    return <a href={href} className='ms-write-review__sign-in-btn msc-btn' aria-label={ariaLabel} {...attributes}>{text}</a>;
};

interface IModalToggleProps {
    innerRef: React.RefObject<HTMLButtonElement> | undefined;
    id: string;
    text: string;
    ariaLabel: string;
    telemetryContent?: ITelemetryContent;
    onClick():  void;
}

export const ModalToggle: React.FC<IModalToggleProps> = (props: IModalToggleProps) => {
    const {text, onClick, ariaLabel, innerRef, telemetryContent, id} = props;
    const payLoad = getPayloadObject('click', telemetryContent!, TelemetryConstant.WriteReview);
    const attributes = getTelemetryAttributes(telemetryContent!, payLoad);
    return <button id={id} className='ms-write-review__toggle msc-btn' aria-label={ariaLabel} onClick={onClick} ref={innerRef} {...attributes}>{text}</button>;
};