
import { OrderTemplate } from '@msdyn365-commerce-modules/retail-actions';
import { Button, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { AddOrderTemplateToCartComponent, ILinesAddedToCartDialogResources, IOrderTemplateNameDialogResources, RenameOrderTemplateComponent } from '@msdyn365-commerce/components';
import { IComponentProps, ICoreContext } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';

export interface IOrderTemplateActionsData {
    orderTemplate: OrderTemplate;
}

export interface IOrderTemplateActionsProps extends IComponentProps<IOrderTemplateActionsData>  {
    context: ICoreContext;
    telemetryContent?: ITelemetryContent;
    handlers: {
        onRemoveTemplate(orderTemplateId: string): void;
        onRenameTemplate(orderTemplate: ProductList): Promise<void>;
    };
    renameOrderTemplateDialogStrings: IOrderTemplateNameDialogResources;
    resources: {
        addTemplateToBagButtonText: string;
        renameTemplateButtonText: string;
        deleteTemplateButtonText: string;
        closeWindowButtonText: string;
        addToCartFailureMessage: string;
        addToCartSuccessMessage: string;
        addToCartProcessMessage: string;
        linesAddedToCartDialogResources: ILinesAddedToCartDialogResources;
    };
}

export interface IOrderTemplateActionsViewProps {
    addTemplateToBag: React.ReactNode;
    renameTemplate?: React.ReactNode;
    removeTemplate?: React.ReactNode;
}

export const OrderTemplateActionsView = (props: IOrderTemplateActionsProps): IOrderTemplateActionsViewProps => {
    const { resources } = props;
    const { deleteTemplateButtonText } = resources;

    const removeOrderAction = (event: React.MouseEvent<HTMLElement>) => {
        return _removeOrderTemplate(props);
    };

    return {
        addTemplateToBag: _getOrderTemplateAddToCartDialog(props),
        renameTemplate: _getOrderTemplateRenameControl(props),
        removeTemplate: (
            <Button className='ms-order-template-action-bar__delete-template-button' onClick={removeOrderAction}>
                {deleteTemplateButtonText}
            </Button>
        )
    };
};

const _removeOrderTemplate = (props: IOrderTemplateActionsProps): void => {
    const { data: { orderTemplate }, handlers } = props;
    const { onRemoveTemplate } = handlers;

    if (onRemoveTemplate) {
        onRemoveTemplate(orderTemplate.productList.Id);
    }
};

const _getOrderTemplateAddToCartDialog = (props: IOrderTemplateActionsProps): React.ReactNode => {
    const { data: { orderTemplate }, typeName, id, context, telemetryContent, resources } = props;
    const {
        addTemplateToBagButtonText,
        closeWindowButtonText,
        addToCartFailureMessage,
        addToCartSuccessMessage,
        addToCartProcessMessage,
        linesAddedToCartDialogResources
    } = resources;

    const dialogStrings = {
        closeWindowButtonText: closeWindowButtonText,
        addToCartFailureMessage: addToCartFailureMessage,
        addToCartSuccessMessage: addToCartSuccessMessage,
        addToCartProcessMessage: addToCartProcessMessage,
        linesAddedToCartDialogResources: linesAddedToCartDialogResources
    };

    return (
        <AddOrderTemplateToCartComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{orderTemplate}}
            className=''
            addToCartText={addTemplateToBagButtonText}
            dialogStrings={dialogStrings}
            telemetryContent={telemetryContent}
        />
    );
};

const _getOrderTemplateRenameControl = (props: IOrderTemplateActionsProps): React.ReactNode => {
    const { renameOrderTemplateDialogStrings, resources, context, handlers } = props;
    return (
        <RenameOrderTemplateComponent
            onRenameTemplate={handlers.onRenameTemplate}
            className={'msc-order-template-name-dialog'}
            context={context}
            id={props.id}
            data={{orderTemplate: props.data.orderTemplate.productList}}
            typeName={props.typeName}
            renameOrderTemplateDialogStrings={renameOrderTemplateDialogStrings}
            renameOrderTemplateButtonText={resources.renameTemplateButtonText}
        />
    );
};