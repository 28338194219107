import {
    addItemToOrderTemplate,
    AddItemToOrderTemplateInput,
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getSelectedVariant,
    SelectedVariantInput
} from '@msdyn365-commerce-modules/retail-actions';
import { Button, Modal, ModalBody, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { ICoreContext, IImageSettings } from '@msdyn365-commerce/core';
import { ProductListLine, ProductSearchCriteria, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { get } from 'lodash';
import { computed } from 'mobx';
import React from 'react';
import { IProductConfigurationState, NotFoundComponent, ProductConfiguration, ProductList } from './';

import Spinner from './spinner';

export interface IAddLineToTemplateProps {
    context: ICoreContext;
    resources: IAddLineToTemplateResources;
    orderTemplateId: string;
    imageSettings?: IImageSettings;
}

export interface IAddLineToTemplateResources {
    addLineModalLinkText: string;
    searchButtonAriaLabel: string;
    searchInputAriaLabel: string;
    searchModalPlaceholderText: string;
    selectProductButtonText: string;
    addItemToTemplateText: string;
    addLineProductUnitPricePrefix: string;
    backButtonText: string;
    decrementButtonAriaLabel: string;
    incrementButtonAriaLabel: string;
    quantitySelectLabel: string;
    addLineProductUnitOfMeasurePrefix: string;
    notFoundSearchErrorNotice: string;
    notFoundSearchErrorRedediation: string;
    searchErrorMessage: string;
    productDimensionTypeColor: string;
    productDimensionTypeConfiguration: string;
    productDimensionTypeSize: string;
    productDimensionTypeStyle: string;
    searchResultsCountVerbage: string;
    searchResultsCountSubject: string;
    addToTemplateConfirmation: string;
    totalPriceLabel: string;
    progressNotificationText: string;
    addToTemplateDuplicateError: string;
    addToTemplateGenericError: string;
    dimensionMissingError: string;
}

enum CONTENT {
    Search,
    ProductList,
    ProductConfiguration,
    NotFound,
    Error,
    Loading
}

interface IAddLineToTemplateState {
    isOpen: boolean;
    query: string;
    products: ProductSearchResult[];
    content: number;
    selectedProduct: SimpleProduct | null;
    selectedProductDimensions?: ProductDimensionFull[];
    selectedQuantity?: number;
    reloadPage?: boolean;
}

/**
 * Add Lines to order template
 */
export class AddLineToTemplate extends React.Component<IAddLineToTemplateProps, IAddLineToTemplateState> {
    public state: IAddLineToTemplateState = {
        isOpen: false,
        query: '',
        products: [],
        content: CONTENT.Search,
        selectedProduct: null,
    };
    public defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=64&h=64&m=6`, w: 0, h: 0 },
            lg: { q: `w=64&h=64&m=6`, w: 0, h: 0 },
            xl: { q: `w=64&h=64&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };
    public searchTextInput: React.RefObject<HTMLInputElement> = React.createRef();// @TODO public/private

    constructor(props: IAddLineToTemplateProps) {
        super(props);
    }

    public onComponentDidMount(): void {
        this.searchTextInput.current?.focus();
    }

    public onComponentDidUpdate(): void {
        this.searchTextInput.current?.focus();
    }

    // @ts-ignore
    @computed get showBackArrow(): boolean {
        return this.state.content === CONTENT.ProductConfiguration;
    }

    public render(): JSX.Element {
        const {
            resources: { addLineModalLinkText }
         } = this.props;

        const modalProps = {
             ...this.props,
            className: 'msc-add-line-to-template',
            toggle: this._toggleModalHandler,
            isOpen: this.state.isOpen
        };

        return (
            <>
                <Modal {...modalProps}>
                    <ModalHeader toggle={this._toggleModalHandler}>
                        {this.showBackArrow && <button
                            type='button'
                            className='msc-modal__back-button'
                            aria-label='Back to search results'
                            onClick={this._onBackButtonClickHandler}
                        />}{addLineModalLinkText}
                    </ModalHeader>
                    {this._renderContent()}
                </Modal>
                <Button className={'msc-add-line-to-template__button'} aria-label={addLineModalLinkText} onClick={this._toggleModalHandler}><span/>{addLineModalLinkText}</Button>
            </>
        );
    }

    private _renderSearchForm = () => {
        const {
            resources: { searchButtonAriaLabel, searchInputAriaLabel, searchModalPlaceholderText }
         } = this.props;

        return (
            <form
                className='msc-add-line-to-template__search-form'
                aria-label={searchButtonAriaLabel}
                name='add-line-to-template-search-form'
                role='form'
                autoComplete='off'
                onSubmit={this._onSearchSubmit}
            >
                <input
                    type='text'
                    autoFocus
                    aria-label={searchInputAriaLabel}
                    className={'msc-form-control msc-add-line-to-template__search-input'}
                    placeholder={searchModalPlaceholderText}
                    value={this.state.query}
                    onChange={this._onInputChange}
                    ref={this.searchTextInput}
                    maxLength={200}
                />
                <button
                    className={'msc-add-line-to-template__search-button'}
                    aria-label={'Add To Template Search Button'}
                    color={'primary'}
                />
            </form>
        );
    };

    private _onSearchSubmit = (event: React.FormEvent): void => {
        event.preventDefault();

        const query = get(this, 'searchTextInput.current.value', null);

        if (!query) {
            return;
        }

        this.setState({
            content: CONTENT.Loading
        });

        this._getSearchResults(query)
            .then(result => {
                if (result.length === 0) {
                    this.setState({
                        products: result,
                        content: CONTENT.NotFound,
                    });
                } else {
                    this.setState({
                        products: result,
                        content: CONTENT.ProductList,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    content: CONTENT.Error,
                });
            });
    };

    private _onQuantityChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedQuantity: +event.target.value
        });
    }

    private _onSelectItem = async (product: ProductSearchResult): Promise<ProductDimensionFull[]> => {
        const {
            actionContext,
            request: {
                apiSettings: { channelId }
            }
        } = this.props.context;
        const varianteInput = new SelectedVariantInput(product.RecordId, channelId, []);
        const productVariant = await getSelectedVariant(varianteInput, actionContext);

        if (!productVariant) {
            this.props.context.telemetry.error('Error retrieving product variant');
            return Promise.resolve([]);
        }

        const dimensions = await this._getProductDimensions(product as SimpleProduct);

        this.setState({
            selectedProductDimensions: dimensions,
            content: CONTENT.ProductConfiguration,
            selectedProduct: productVariant
        });

        return dimensions;
    };

    private _onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            query: event.target.value
        });
    };

    private _onBackButtonClickHandler = (): void => {
        this.setState({
            content: CONTENT.ProductList
        });
    }

    private _highlightSearchTerm = (name: string) => {
        const parsedName = name && unescape(name.replace(new RegExp(this.state.query, 'i'), '<span>$&</span>'));
        // tslint:disable-next-line:react-no-dangerous-html
        return <div className='msc-add-line-to-template__product__name' dangerouslySetInnerHTML={{__html: parsedName || ''}}/>;
    };

    private _resultCountText = (): string => {
        return this.props.resources.searchResultsCountVerbage
            .replace('{count}', this.state.products.length.toString())
            .replace('{subject}', `${this.props.resources.searchResultsCountSubject}${(this.state.products.length > 1 ? 's' : '')}`);
    }

    private _renderContent(): React.ReactNode | null {
        switch (this.state.content) {
            case CONTENT.ProductConfiguration:
                const { selectedProduct, selectedProductDimensions } = this.state;
                const viewProps = {
                    ...this.props,
                    imageSettings: this.props.imageSettings || this.defaultImageSettings,
                    product: selectedProduct!,
                    dimensions: selectedProductDimensions!,
                    addToTemplateHandler: this._addItemToTemplateHandler,
                    onQuantityChangeHandler: this._onQuantityChangeHandler,
                    highlightSearchTerm: this._highlightSearchTerm,
                    searchForm: this._renderSearchForm()
                };

                return <ProductConfiguration {...viewProps}/>;

            case CONTENT.ProductList:
                const productProps = {
                    ...this.props,
                    imageSettings: this.props.imageSettings || this.defaultImageSettings,
                    clickHandler: this._onSelectItem,
                    products: this.state.products,
                    highlightSearchTerm: this._highlightSearchTerm,
                    searchForm: this._renderSearchForm(),
                    renderResultCountText: this._resultCountText
                };

                return (
                    <ModalBody>
                        <ProductList {...productProps}/>
                    </ModalBody>
                );

            case CONTENT.Loading:
                return (
                    <ModalBody>
                        {this._renderSearchForm()}
                        <Spinner className='msc-add-line-to-template' msg={this.props.resources.progressNotificationText}/>
                    </ModalBody>
                );

            case CONTENT.NotFound:
                const { notFoundSearchErrorNotice, notFoundSearchErrorRedediation } = this.props.resources;

                return (
                    <ModalBody>
                        <p className='msc-add-line-to-template__search-result-count'>{this._resultCountText()}</p>
                        {this._renderSearchForm()}
                        <NotFoundComponent error={notFoundSearchErrorNotice} msg={notFoundSearchErrorRedediation}/>
                    </ModalBody>
                );

            case CONTENT.Error:
                return (
                    <ModalBody>
                        {this._renderSearchForm()}
                        <div className='msc-alert-danger'>{this.props.resources.searchErrorMessage}</div>
                    </ModalBody>
                );

            default:
                return (
                    <ModalBody>
                        {this._renderSearchForm()}
                    </ModalBody>
                );
        }
    }

    private _addItemToTemplateHandler = async (config: IProductConfigurationState): Promise<ProductListLine> => {
        const { orderTemplateId, context: { actionContext } } = this.props;
        const input = new AddItemToOrderTemplateInput(
            orderTemplateId,
            config.product.RecordId,
            config.quantity,
            config.product.DefaultUnitOfMeasure || 'ea'
        );

        const result = await addItemToOrderTemplate(input, actionContext);

        this.setState({
            reloadPage: true
        });

        return result;
    }

    private _getSearchResults(searchText: string): Promise<ProductSearchResult[]> {
        const {
            context: {
                actionContext,
                request: {
                    apiSettings: { channelId, catalogId }
                }
            }
        } = this.props;
        const searchCriteriaInput: ProductSearchCriteria = {};

        searchCriteriaInput.Context = {
            ChannelId: channelId,
            CatalogId: catalogId
        };
        searchCriteriaInput.IncludeAttributes = true;
        searchCriteriaInput.SearchCondition = searchText;

        return searchByCriteriaAsync({ callerContext: actionContext }, searchCriteriaInput);
    }

    private async _getProductDimensions(product: SimpleProduct): Promise<ProductDimensionFull[]> {
        const {
            context: {
                actionContext,
                request: {
                    apiSettings: { channelId }
                }
            }
        } = this.props;
        const id = product.MasterProductId ? product.MasterProductId : product.RecordId;

        return getDimensionsForSelectedVariant(
            new GetDimensionsForSelectedVariantInput(id, channelId, []),
            actionContext
        );
    }

    private _toggleModalHandler = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            content: CONTENT.Search
        });

        if (this.state.reloadPage) {
            window.location.reload();
            this.setState({
                reloadPage: false
            });
        }

    };
}
