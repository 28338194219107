import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { Button } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { IImageSettings, Image } from '@msdyn365-commerce/core';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import React, { useState } from 'react';
import { IAddLineToTemplateProps } from '.';

export interface IProductListProps extends IAddLineToTemplateProps {
    products: ProductSearchResult[];
    imageSettings: IImageSettings;
    searchForm: React.ReactNode;
    clickHandler(product: ProductSearchResult): Promise<ProductDimensionFull[]>;
    highlightSearchTerm(name: string): React.ReactNode;
    renderResultCountText(): string;
}

export const ProductList = (props: IProductListProps) => {
    const {
        products,
        context: {
            actionContext: {
                requestContext: { apiSettings }
            },
            request: {
                gridSettings
            },
            cultureFormatter: {
                formatCurrency,
                // @ts-ignore
                currencyCode
            }
        },
        clickHandler,
        highlightSearchTerm,
        renderResultCountText,
        resources: { selectProductButtonText },
        imageSettings
    } = props;

    return (
        <>
            <p className='msc-add-line-to-template__search-result-count'>{renderResultCountText()}</p>
            {props.searchForm}
            <div className='msc-add-line-to-template__product-list'>
                {products.map((product: ProductSearchResult, index: number) => {
                    const { Price, ItemId, Name, PrimaryImageUrl } = product;
                    const price = formatCurrency(Price.toFixed(2), currencyCode);
                    const fallBackImg = getFallbackImageUrl(ItemId, apiSettings) || '';

                    return (
                        <div key={`product-${index}`} className='msc-add-line-to-template__product'>
                            <div className='msc-add-line-to-template__product__positioning-container-1'>
                                <Image
                                    src={PrimaryImageUrl || ''}
                                    fallBackSrc={fallBackImg}
                                    className={'thumbnail'}
                                    gridSettings={gridSettings!}
                                    imageSettings={imageSettings}
                                    loadFailureBehavior='empty'
                                />
                                <div className='msc-add-line-to-template__product__attributes'>
                                    <div className='msc-add-line-to-template__product__id'>{ItemId}</div>
                                    {highlightSearchTerm(Name || '')}
                                </div>
                            </div>
                            <div className='msc-add-line-to-template__product__price-container'>
                                <span>{price}</span>
                                <ProductSelectButton product={product} buttonText={selectProductButtonText} clickHandler={clickHandler}/>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

interface IProductSelectButton {
    product: ProductSearchResult;
    buttonText: string;
    clickHandler(product: ProductSearchResult): Promise<ProductDimensionFull[]>;
}

const ProductSelectButton = (props: IProductSelectButton) => {
    const [isBusy, setBusy] = useState(false);
    const { product, buttonText, clickHandler } = props;
    const getClickHandler = async () => {
        setBusy(!isBusy);
        await clickHandler(product);
        setBusy(!isBusy);
    };

    return (
        <Button
            className={classnames('msc-add-line-to-template__product__select-button', { 'is-busy': isBusy })}
            aria-label={buttonText}
            onClick={getClickHandler}
        >
            {buttonText}
        </Button>
    );
};