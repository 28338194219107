import { Button } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { AddLineToTemplate } from '.';
import { IOrderTemplateLineViewModel } from '../order-template';
import { IOrderTemplateData } from '../order-template.data';
import { IOrderTemplateProps } from '../order-template.props.autogenerated';

interface IOrderTemplateBulkActionsProps extends IOrderTemplateProps<IOrderTemplateData> {
    orderTemplateId: string;
    selectedLines: IOrderTemplateLineViewModel[];
    isMinifiedView: boolean;
    onAddSelectionToBag(): void;
    onRemoveSelection(): void;
}

interface IOrderTemplateBulkActionsState {
    isAddDisabled: boolean;
    isRemoveDisabled: boolean;
}

/**
 * Bulk Order Template line actions
 */
export class OrderTemplateBulkActions extends React.PureComponent<IOrderTemplateBulkActionsProps, IOrderTemplateBulkActionsState> {

    public state: IOrderTemplateBulkActionsState = {
        isAddDisabled: true,
        isRemoveDisabled: true
    };

    public componentDidUpdate(prevProps: IOrderTemplateBulkActionsProps): void {
        if (prevProps.selectedLines.length !== this.props.selectedLines.length) {
            this.setState({
                isAddDisabled: !this.props.selectedLines.length,
                isRemoveDisabled: !this.props.selectedLines.length
            });
        }
    }

    public render(): JSX.Element {
        const { addLineModalLinkText,
                searchButtonAriaLabel,
                searchInputAriaLabel,
                searchModalPlaceholderText,
                selectProductButtonText,
                addItemToTemplateText,
                addLineProductUnitPricePrefix,
                backButtonText,
                decrementButtonAriaLabel,
                incrementButtonAriaLabel,
                quantitySelectLabel,
                addLineProductUnitOfMeasurePrefix,
                notFoundSearchErrorNotice,
                notFoundSearchErrorRedediation,
                searchErrorMessage,
                productDimensionTypeColor,
                productDimensionTypeConfiguration,
                productDimensionTypeSize,
                productDimensionTypeStyle,
                addSelectedToBagButtonText,
                removeSelectedToBagButtonText,
                searchResultsCountVerbage,
                searchResultsCountSubject,
                addToTemplateConfirmation,
                totalPriceLabel,
                progressNotificationText,
                addToTemplateDuplicateError,
                addToTemplateGenericError,
                dimensionMissingError,
            } = this.props.resources;
        const addLineProps = {
            context: this.props.context,
            resources: {
                addLineModalLinkText,
                searchButtonAriaLabel,
                searchInputAriaLabel,
                searchModalPlaceholderText,
                selectProductButtonText,
                addItemToTemplateText,
                addLineProductUnitPricePrefix,
                backButtonText,
                decrementButtonAriaLabel,
                incrementButtonAriaLabel,
                quantitySelectLabel,
                addLineProductUnitOfMeasurePrefix,
                notFoundSearchErrorNotice,
                notFoundSearchErrorRedediation,
                searchErrorMessage,
                productDimensionTypeColor,
                productDimensionTypeConfiguration,
                productDimensionTypeSize,
                productDimensionTypeStyle,
                searchResultsCountVerbage,
                searchResultsCountSubject,
                addToTemplateConfirmation,
                totalPriceLabel,
                progressNotificationText,
                addToTemplateDuplicateError,
                addToTemplateGenericError,
                dimensionMissingError
            },
            imageSettings: this.props.config.imageSettings,
            orderTemplateId: this.props.orderTemplateId
        };

        return (
            <>
                <AddLineToTemplate {...addLineProps}/>
                <Button
                    onClick={this.addHandler}
                    disabled={this.state.isAddDisabled}
                    className='add-selected-to-bag'
                    aria-label={addSelectedToBagButtonText}
                >
                    <span/>{!this.props.isMinifiedView && addSelectedToBagButtonText}
                </Button>
                <Button
                    onClick={this.removeHandler}
                    disabled={this.state.isRemoveDisabled}
                    className='remove-selected'
                    aria-label={removeSelectedToBagButtonText}
                >
                    <span/>{!this.props.isMinifiedView && removeSelectedToBagButtonText}
                </Button>
            </>
        );
    }

    private addHandler = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            isAddDisabled: true
        });

        this.props.onAddSelectionToBag();

        this.setState({
            isAddDisabled: false
        });
    };

    private removeHandler = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            isRemoveDisabled: true
        });

        this.props.onRemoveSelection();
    };
}