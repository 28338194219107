import {
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getFallbackImageUrl,
    getPriceForSelectedVariant,
    getProductAvailabilitiesForSelectedVariant,
    getSelectedVariant,
    IProductInventoryInformation,
    PriceForSelectedVariantInput,
    ProductAvailabilitiesForSelectedVariantInput,
    SelectedVariantInput
 } from '@msdyn365-commerce-modules/retail-actions';
import { Alert, Button, ITelemetryContent, ModalBody, ModalFooter } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { IImageSettings, Image } from '@msdyn365-commerce/core';
import { ProductDimensionValue, ProductListLine } from '@msdyn365-commerce/retail-proxy';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import classnames from 'classnames';
import React from 'react';
import { Dropdown, IAddLineToTemplateProps, IAddLineToTemplateResources, IDropdownItemProps, IDropdownOnSelection } from '.';
import OrderTemplateQuantity from '../common/order-template-quantity';

export interface IProductCnnfigurationProps extends IAddLineToTemplateProps {
    product: SimpleProduct;
    dimensions: ProductDimensionFull[];
    imageSettings: IImageSettings;
    telemetryContent?: ITelemetryContent;
    searchForm: React.ReactNode;
    addToTemplateHandler(params: IProductConfigurationState): Promise<ProductListLine>;
    highlightSearchTerm(name: string): React.ReactNode;
}

export interface IProductConfigurationState {
    quantity: number;
    unitPrice: number;
    totalPrice: number;
    product: SimpleProduct;
    dimensions: ProductDimensionFull[];
    selectedDimensions: {};
    productAvailableQuantity?: IProductInventoryInformation;
    buttonDisabled: boolean;
    showAddConfirmation: boolean;
    errorMessage?: string;
    isBusy: boolean;
    errors: {dimension?: string[]; add?: string};
}

/**
 * Configure selected product for addition to order template
 */
export class ProductConfiguration extends React.Component<IProductCnnfigurationProps, IProductConfigurationState> {
    private selectedDimensions: {} = {};

    constructor(props: IProductCnnfigurationProps) {
        super(props);

        this.state = {
            quantity: 1,
            unitPrice: props.product.Price,
            totalPrice: props.product.Price,
            product: props.product,
            dimensions: props.dimensions.sort((a, b) => a.DimensionTypeValue - b.DimensionTypeValue),
            selectedDimensions: {},
            buttonDisabled: false,
            showAddConfirmation: false,
        } as IProductConfigurationState;
    }

    public render(): JSX.Element {
        const {
            context: {
                actionContext: {
                    requestContext: { apiSettings }
                },
                request: { gridSettings }
             },
             resources: {
                addItemToTemplateText,
                addLineProductUnitPricePrefix,
                decrementButtonAriaLabel,
                incrementButtonAriaLabel,
                quantitySelectLabel,
                addLineProductUnitOfMeasurePrefix,
                totalPriceLabel
            },
            imageSettings,
            highlightSearchTerm
        } = this.props;
        const { buttonDisabled, product, quantity, unitPrice, totalPrice, showAddConfirmation, errors } = this.state;

        return (
            <>
                <ModalBody>
                    {this.props.searchForm}
                    <div className='msc-add-line-to-template__product-config'>
                        <div className='msc-add-line-to-template__product__positioning-container-2'>
                            <div className='msc-add-line-to-template__product__positioning-container-1'>
                                <Image
                                    src={product?.PrimaryImageUrl || ''}
                                    fallBackSrc={
                                        getFallbackImageUrl(product?.ItemId, apiSettings) || ''
                                    }
                                    className={'thumbnail'}
                                    imageSettings={imageSettings}
                                    gridSettings={gridSettings!}
                                    loadFailureBehavior='empty'
                                />
                                <div className='msc-add-line-to-template__product__attributes'>
                                        <div className='msc-add-line-to-template__product__id'>{product?.ItemId}</div>
                                        {highlightSearchTerm(product?.Name || '')}
                                        <div className='msc-add-line-to-template__product__unit-price'>{addLineProductUnitPricePrefix} {this._formatPrice(unitPrice)}</div>
                                        <div className='msc-add-line-to-template__product__uom'>{addLineProductUnitOfMeasurePrefix} {product?.DefaultUnitOfMeasure}</div>
                                </div>
                            </div>
                            <div className='msc-add-line-to-template__product-config__dimensions'>
                                {this._getDimensionsNodes()}
                            </div>
                        </div>
                        <div className='quantity-container'>
                            <div>{quantitySelectLabel}</div>
                            <OrderTemplateQuantity
                                id={'msc-add-line-to-template__product-config__quantity'}
                                currentCount={quantity}
                                onChange={this._onQuantityChange}
                                inputQuantityAriaLabel='Press to increment quantity by 1'
                                max={10000000}
                                decrementButtonAriaLabel={decrementButtonAriaLabel}
                                incrementButtonAriaLabel={incrementButtonAriaLabel}
                            />
                        </div>
                    </div>
                    <div className='msc-add-line-to-template__product__total-price'>{totalPriceLabel} {this._formatPrice(totalPrice)}</div>
                </ModalBody>
                <ModalFooter>
                    {showAddConfirmation && <div className='msc-add-line-to-template__add-success msc-alert-success msc-alert'><span aria-hidden='true'/>{this._getConfirmationMessage()}</div>}
                    {errors?.add && <div className='msc-add-line-to-template__add-error msc-alert-danger msc-alert'><span className='msi-exclamation-triangle' aria-hidden='true'/>{errors?.add}</div>}
                    <Button
                        className={classnames('msc-add-line-to-template__add-configured-product-button', { 'is-busy': this.state.isBusy })}
                        aria-label={addItemToTemplateText}
                        onClick={this._addToTemplateHandler}
                        disabled={buttonDisabled}
                    >
                        {addItemToTemplateText}
                    </Button>
                </ModalFooter>
            </>
        );
    }

    private _getConfirmationMessage(): string {
        const selectedDimensionNames = this.state.dimensions.map(dimension => {
            let variant = dimension.DimensionValues?.find(value => value.RecordId === this.selectedDimensions[dimension.DimensionTypeValue])?.Value;
            if (dimension.DimensionTypeValue === 3) {
                variant = `size ${variant}`;
            }
            return variant;
        }).filter(value => value);

        return this.props.resources.addToTemplateConfirmation
            .replace('{count}', this.state.quantity.toString())
            .replace('{productAndDimensions}', `${this.state.product.Name}, ${Object.values(selectedDimensionNames).join(', ')}`);

    }

    private _getDimensionsNodes(): React.ReactElement[] {
        const { dimensions } = this.state;
        const { resources } = this.props;

        return dimensions?.map((dimension: ProductDimensionFull) => {
            const { DimensionValues, DimensionTypeValue } = dimension;
            const mapDimensions = (value: ProductDimensionValue): IDropdownItemProps => ({
                id: value.RecordId,
                value: value.Value || ''
            });
            const dimensionName = this._getDropdownName(DimensionTypeValue, resources);
            const dropdownList: IDropdownItemProps[] = DimensionValues ? DimensionValues.map<IDropdownItemProps>(mapDimensions) : [];
            dropdownList.unshift({id:0, value:`Choose a ${dimensionName}`});

            return (
                <div key={DimensionTypeValue}>
                    <div>{dimensionName}</div>
                    {this.state.errors?.dimension?.includes(DimensionTypeValue.toString()) && (
                        <Alert color='danger' assertive={true} aria-label={'aria label'}>
                            <div className='msc-alert__header' aria-hidden='true'>
                                <span className='msi-exclamation-triangle' />
                                <span>{this._getDimensionsError(dimensionName)}</span>
                            </div>
                        </Alert>
                    )}
                    <Dropdown
                        dropdownId={DimensionTypeValue}
                        dropdownName={this._getDropdownName(DimensionTypeValue, resources)}
                        dropdownList={dropdownList}
                        onChange={this._onDimensionChanged}
                    />
                </div>
            );
        });
    }

    private _getDimensionsError = (dimensionName: string) => this.props.resources.dimensionMissingError.replace(/\{dimensionName\}/g, dimensionName);

    private _addToTemplateHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // hide confirmation of previous addition when adding multiple items
        this.setState({
            isBusy: true,
            showAddConfirmation: false,
            errors: {...this.state.errors, add: undefined}
        });

        const missingDimensions = this.state.dimensions.map(dimension => dimension.DimensionTypeValue.toString()).filter(type => !this.selectedDimensions[type]);

        this.setState({
            errors: {dimension: missingDimensions}
        });
        if (missingDimensions.length) {
            this.setState({
                isBusy: false
            });
            return;
        }

        this.props.addToTemplateHandler({...this.state})
            .then(status => {
                // validate against the existance of a ProductListId
                if (status.ProductListId) {
                    this.setState({
                        isBusy: false,
                        showAddConfirmation: true
                    });
                }
            })
            .catch(error => {
                const { addToTemplateDuplicateError, addToTemplateGenericError } = this.props.resources;
                let errorMessage;

                if (error.error.name === 'Microsoft_Dynamics_Commerce_Runtime_DuplicateObject') {
                    errorMessage = addToTemplateDuplicateError;
                } else {
                    errorMessage = addToTemplateGenericError;
                }

                this.setState({
                    isBusy: false,
                    errors: {...this.state.errors, add: errorMessage}
                });
                this.props.context.telemetry.error('Error adding item to order template');
            });
    };

    private _onQuantityChange = (newValue: number) => {
        this.setState({
            quantity: newValue,
            totalPrice: newValue * this.props.product.Price
        });
    }

    private _updateDimensionValue = (productDimensionFull: ProductDimensionFull, newValueId: string | undefined): ProductDimensionValue | undefined => {
        if (newValueId && productDimensionFull.DimensionValues) {
            return productDimensionFull.DimensionValues.find(dimension => dimension.RecordId === +newValueId);
        }

        return undefined;
    };

    // rehydrate the selected variants price, available dimensions & sizes
    private _onDimensionChanged = async (selection: IDropdownOnSelection): Promise<void> => {
        const { product, dimensions, context } = this.props;
        const {
            actionContext,
            request: { apiSettings: { channelId } }
        } = context;

        // remaining error notifications for missing dimensions
        const remainingDimensionsErrors = this.state.errors?.dimension?.filter(id => id !== selection.dropdownId.toString());

        this.setState({
            isBusy: false,
            showAddConfirmation: false,
            buttonDisabled: true,
            errors: {add: undefined, dimension: remainingDimensionsErrors}
        });

        // update currenlty selected dimensions
        this.selectedDimensions[selection.dropdownId] = +selection.selectId;

        const mappedDimensions = dimensions?.map(dimension => {
            return {
                DimensionTypeValue: dimension.DimensionTypeValue,
                DimensionValue: this._updateDimensionValue(dimension, this.selectedDimensions[dimension.DimensionTypeValue]) || dimension.DimensionValue,
                ExtensionProperties: dimension.ExtensionProperties
            };
        }).filter(dimension => {
            return dimension && dimension.DimensionValue;
        });

        const variantProduct = (await getSelectedVariant(
            new SelectedVariantInput(
                product.MasterProductId ? product.MasterProductId : product.RecordId,
                channelId,
                mappedDimensions
            ),
            actionContext
        ));

        if (!variantProduct) {
            this.props.context.telemetry.error(`Error retrieving variant product for product ${product.MasterProductId ? product.MasterProductId : product.RecordId}`);
            return;
        }

        const dimensionInput = new GetDimensionsForSelectedVariantInput(
            variantProduct.RecordId,
            channelId,
            mappedDimensions
        );
        const variantDimensions = await getDimensionsForSelectedVariant(dimensionInput, actionContext);

        if (!variantDimensions) {
            this.props.context.telemetry.error('Error retrieving dimensions for reconfigured product variant');
            this.setState({
                buttonDisabled: false
            });
            return;
        }

        const availabilityInput = new ProductAvailabilitiesForSelectedVariantInput(
            product.MasterProductId ? product.MasterProductId : product.RecordId,
            channelId
        );
        // @TODO sync UX quantity w/ actual availibility
        const newAvailableQuantity = await getProductAvailabilitiesForSelectedVariant(availabilityInput, actionContext);
        const priceInput = new PriceForSelectedVariantInput(product.RecordId, channelId);
        const productPrice = await getPriceForSelectedVariant(priceInput, actionContext);

        if (!productPrice) {
            this.props.context.telemetry.error('Error retrieving price for reconfigured product variant');
            this.setState({
                buttonDisabled: false
            });
            return;
        }

        this.setState({
            buttonDisabled: false,
            productAvailableQuantity: newAvailableQuantity && newAvailableQuantity[0] || undefined,
            unitPrice: +(productPrice.BasePrice || product.Price),
            dimensions: variantDimensions.sort((a, b) => a.DimensionTypeValue - b.DimensionTypeValue),
            product: variantProduct
        });
    }

    private _getDropdownName = (dimensionType: number, resources: IAddLineToTemplateResources): string => {
        switch (dimensionType) {
            case 1: // Color
                return resources.productDimensionTypeColor;
            case 2: // Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // Size
                return resources.productDimensionTypeSize;
            case 4: // Style
                return resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };

    private _formatPrice(price: number): string {
        const {
            context: {
                cultureFormatter: {
                    formatCurrency,
                    // @ts-ignore
                    currencyCode
                }
            }
        } = this.props;

        return formatCurrency(price.toFixed(2), currencyCode);
    }
}