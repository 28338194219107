import { debounce } from 'lodash';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IQuantityProps {
    min?: number;
    max: number;
    currentCount?: number;
    disabled?: boolean;
    incrementGlyphClass?:string;
    decrementGlyphClass?:string;
    decrementButtonAriaLabel?: string;
    incrementButtonAriaLabel?: string;
    inputQuantityAriaLabel?: string;
    id?: string;
    onChange?(newValue: number):void;
}

interface IQuantityState {
    currentInput: number;
}
/**
 * Quantity Component - This component is used to add or remove quantity
 */
@observer
export default class OrderTemplateQuantity extends React.PureComponent<IQuantityProps, IQuantityState> {
    public static defaultProps: Partial<IQuantityProps> = {
        min: 1,
        decrementGlyphClass: 'fas fa-minus',
        incrementGlyphClass: 'fas fa-plus'
    };

    private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    constructor(props: IQuantityProps) {
        super(props);
        this.state = { currentInput: props.currentCount || 1 };
        this._handleChange = this._handleChange.bind(this);

        reaction(
            () => this.props.currentCount,
            () => {
                this.setState({ currentInput: this.props.currentCount || 1 });
            }
        );
    }

    public render(): JSX.Element {
        const glyphMinusClassName: string = `${this.props.decrementGlyphClass!} quantity__controls-glyph`;
        const glyphPlusClassName: string = `${this.props.incrementGlyphClass!} quantity__controls-glyph`;
        const disabled = this.props.currentCount === this.props.min;
        const incDisabled = this.props.currentCount === this.props.max;
        const currentValue = this.state.currentInput;
        let extraClass = '';
        if(disabled) {
            // The quantity has reached its boundaries (max or min)
            extraClass ='disabled';

        } else if(this.props.disabled) {
            // this.props.disabled shows if the state is not succeded yet
            extraClass = 'transition';
        }
        const getChangeHandler = (amount: number) => () => this._onIncrementalChange(amount);

        return (
            <div className='quantity'/* id={this.props.id}*/>
                <button
                    disabled={this.props.disabled || disabled}
                    title= {disabled ? '' : this.props.decrementButtonAriaLabel}
                    className={`decrement quantity__controls ${extraClass}`}
                    onClick={getChangeHandler(-1)}
                    aria-hidden={true}
                    aria-label={`${this.props.decrementButtonAriaLabel}`}
                    tabIndex={-1}
                    color={'secondary'}
                >
                    <span className={glyphMinusClassName} />
                </button>
                <input
                    type='number'
                    className='quantity-input'
                    pattern='[0-9]*'
                    value={currentValue}
                    onChange={this._handleChange}
                    onBlur={this._validateMin}
                    aria-live='polite'
                    aria-label={`${this.props.inputQuantityAriaLabel}`}
                    role='spinbutton'
                    aria-valuemin={1}
                    aria-valuemax={this.props.max}
                    aria-valuenow={currentValue}
                    ref={this.inputRef}
                    disabled={this.props.disabled}
                />
                <button
                    disabled={this.props.disabled || incDisabled}
                    title={incDisabled ? '' : this.props.incrementButtonAriaLabel}
                    className={`increment quantity__controls ${incDisabled ? 'disabled' : ''}`}
                    onClick={getChangeHandler(1)}
                    aria-hidden={true}
                    aria-label={`${this.props.incrementButtonAriaLabel}`}
                    tabIndex={-1}
                    color={'secondary'}
                >
                    <span className={glyphPlusClassName} />
                </button>
            </div>
        );
    }

    private _onIncrementalChange(amount: number): void {
        const updatedQuantity = this.state.currentInput + amount;

        if (updatedQuantity >= 1 && updatedQuantity <= this.props.max) {
            this.props.onChange && this.props.onChange(updatedQuantity);
        }
    }

    private _handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const currentValue = parseInt((e.target.value), 10);
        const minValue = this.props.min === undefined ? 1 : this.props.min;
        const inputElement = this.inputRef && this.inputRef.current && this.inputRef.current instanceof HTMLInputElement && this.inputRef.current;

        if (currentValue > this.props.max) {
            this.setState(
                { currentInput: this.props.max },
                () => {
                    debounce(
                        () => {
                            this.props.onChange && this.props.onChange(this.state.currentInput);
                        },
                        200)();
                }
            );
        } else {
            this.setState(
                { currentInput: currentValue },
                () => {
                    debounce(
                        () => {
                            if(!isNaN(this.state.currentInput) && !(this.state.currentInput < minValue)) {
                                this.props.onChange && this.props.onChange(this.state.currentInput);

                                if (inputElement) {
                                    inputElement.setAttribute('aria-valuenow', currentValue.toString());
                                    inputElement.setAttribute('value', currentValue.toString());
                                }
                            }
                        },
                        200)();
                }
            );
        }
    }

    private _validateMin = (): void => {
        const minValue = this.props.min === undefined ? 1 : this.props.min;

        if (isNaN(this.state.currentInput) || (this.state.currentInput < minValue)) {
            this.props.onChange && this.props.onChange(minValue);
        } else {
            this.props.onChange && this.props.onChange(this.state.currentInput);
        }
    }
}